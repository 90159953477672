import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import * as S from "./styles";
import AuthenticationContext from "@context/AuthenticationContext";
import NoNavLayout from "@layouts/NoNavLayout";
import { processError } from "@util/processError";
import UnAuthenticatedPage from "@templates/UnAuthenticatedPage";
import SignInForm from "@components/SignIn/SignInForm";
import { theme } from "@constants/theme";
import { Track } from "@util/analytics";

const SignInContainer = ({
  submitRedirect,
  authRedirect,
  meta,
  createAccountLink,
  forgotPasswordLink,
}) => {
  const { login, user } = useContext(AuthenticationContext) || {
    login: null,
    user: null,
  };
  const [serverErrors, setServerErrors] = useState(null);
  const [userAccountLogin, setUserAccountLogin] = useState(false);
  const router = useRouter();

  useEffect(() => {
    // when the user object updates and is no longer null
    // the user is now logged in
    // now we can do the submit redirect
    if (user) {
      router.push(
        user.subscription_status === "employee" ? "/" : submitRedirect
      );
    }
  }, [user]);

  const handleSubmit = async (data) => {
    try {
      setUserAccountLogin(true);

      await login(data);

      // login successful
      Track("User Logged In");
    } catch (e) {
      setUserAccountLogin(false); // reset login flag to false if errors

      // todo: should probably handle on the backend
      // remove once implemented on the backend confidently
      if (e && e.response && e.response.status === 404) {
        setServerErrors(
          "looks like the email or password you entered is incorrect - try again!"
        );
      } else {
        setServerErrors(processError(e));
      }
    }
  };

  return (
    <UnAuthenticatedPage
      redirectTo={authRedirect}
      disableAuthRedirect={userAccountLogin}
    >
      <NoNavLayout
        meta={meta}
        backgroundColor={theme.colors.brand.primary}
        logoColor={theme.colors.grayscale.white}
        withStripes={false}
      >
        <S.SignInContainer_div data-cy="sign-in-container">
          <S.FormWrapper_div theme={theme}>
            <SignInForm
              onSubmit={handleSubmit}
              serverErrors={serverErrors}
              createAccountLink={createAccountLink}
              forgotPasswordLink={forgotPasswordLink}
            />
          </S.FormWrapper_div>
        </S.SignInContainer_div>
      </NoNavLayout>
    </UnAuthenticatedPage>
  );
};

SignInContainer.propTypes = {
  submitRedirect: PropTypes.string.isRequired,
  authRedirect: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  createAccountLink: PropTypes.string.isRequired,
  forgotPasswordLink: PropTypes.string.isRequired,
};

export default SignInContainer;
