import styled, { css } from "styled-components";
import Link from "next/link";

const BrandButton = css`
  position: relative;
  background-color: ${(props) =>
    props.color && props.buttontheme === "default"
      ? props.color.medium_dark
      : props.buttontheme === "default"
      ? props.theme.colors.brand.primary
      : props.theme.colors.grayscale.white};
  border-radius: 28px;
  height: 32px;
  box-sizing: border-box;
  text-decoration: none;
  color: ${(props) =>
    props.buttontheme === "outline" && props.color
      ? props.color.medium_dark
      : props.buttontheme === "outline"
      ? props.theme.colors.brand.primary
      : props.theme.colors.grayscale.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 23px;
  transition: background-color 0.3s, border 0.3s;
  border: 0;
  cursor: pointer;
  outline: none;
  ${(props) =>
    props.buttontheme === "outline" && props.color
      ? `border: 2px solid ${props.color.medium_dark}`
      : props.buttontheme === "outline"
      ? `border: 2px solid ${props.theme.colors.brand.primary}`
      : null};

  ${(props) => props.theme.typography.buttonSmall};
  @media ${(props) => props.theme.device.tablet} {
    padding: 8px 23px;
  }

  p {
    transition: color, 0.3s;
  }

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.color ? props.color.dark : props.theme.colors.brand.secondary};

    ${(props) =>
      props.buttontheme === "outline" && props.color
        ? `border: 2px solid ${props.color.dark}`
        : props.buttontheme === "outline"
        ? `border: 2px solid ${props.theme.colors.brand.secondary}`
        : null};

    p {
      color: ${(props) => props.theme.colors.grayscale.white};
    }
  }

  &:focus-visible {
    // can't use outline property w/ border-radius property because of safari
    // this is a work around
    &:before {
      content: "";
      position: absolute;
      top: -3px;
      bottom: -3px;
      left: -3px;
      border: 3px solid
        ${(props) => `${props.theme.colors.playbooks.yellow.medium_dark}`};
      border-radius: 29px;
      width: calc(100% + 6px);
    }
  }

  // disabled button styles
  ${(props) => (props.disabled ? "pointer-events: none; opacity: 0.5;" : null)}
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

// BrandButton DOM element type may change depending on type prop passed
export const BrandButton_a = styled(Link)`
  ${BrandButton}
`;
export const BrandButton_button = styled.button`
  ${BrandButton}
`;
export const BrandButton_input = styled.input`
  ${BrandButton}
`;
