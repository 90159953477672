import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import * as S from "./styles";
import SVG from "@shared/atoms/SVG";
import { theme } from "@constants/theme";
import { useRouter } from "next/router";
import { Track } from "@util/analytics";

const SignInForm = ({
  onSubmit,
  serverErrors,
  createAccountLink,
  forgotPasswordLink,
}) => {
  const [passwordFieldType, setPasswordFieldType] = useState("password");
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleShowPassword = () => {
    const newType = passwordFieldType === "password" ? "text" : "password";
    setPasswordFieldType(newType);
  };

  const handleOnSubmit = (data) => {
    Track("Sign In Clicked");
    onSubmit(data);
  };

  const handleForgotPassword = () => {
    Track("Forgot Password Clicked");
    router.push(forgotPasswordLink);
  };

  const handleCreateAccount = () => {
    Track("Create Account Clicked");
    router.push(createAccountLink);
  };

  return (
    <S.SignInForm_div autocomplete="on" data-cy="sign-in-form">
      <S.MagicText_h1 type="h1">welcome back!</S.MagicText_h1>
      <S.MagicText_p type="p1">
        New to Realworld?{" "}
        <S.CreateAccount_span onClick={handleCreateAccount}>
          Create account
        </S.CreateAccount_span>
      </S.MagicText_p>

      {/* Form content begins */}
      <S.Form_form onSubmit={handleSubmit(handleOnSubmit)}>
        <S.Input_input
          type="email"
          placeholder="email"
          {...register("email", { required: "email is required" })}
        />
        {errors.email && (
          <S.Error_span id="email_errors">{errors.email.message}</S.Error_span>
        )}

        <S.PasswordFieldsContainer_div>
          <S.PasswordInput_input
            type={passwordFieldType}
            placeholder="password"
            {...register("password", { required: "password is required" })}
          />
          <S.PasswordIcon_span onClick={handleShowPassword}>
            <SVG
              name={`${passwordFieldType === "password" ? "Show" : "Hide"}`}
              color={theme.colors.grayscale.ash}
            />
          </S.PasswordIcon_span>
        </S.PasswordFieldsContainer_div>
        {errors.password && (
          <S.Error_span id="password_errors">
            {errors.password.message}
          </S.Error_span>
        )}

        {serverErrors && (
          <S.Error_span id="serverErrors_errors">{serverErrors}</S.Error_span>
        )}

        <S.ForgotPassword_div onClick={handleForgotPassword} theme={theme}>
          forgot password
        </S.ForgotPassword_div>

        {/* BrandButton with type="submit" will render submit input button */}
        <S.BrandButton_input
          text="sign in"
          onClick={handleSubmit(handleOnSubmit)}
          type="submit"
        />
      </S.Form_form>
    </S.SignInForm_div>
  );
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  serverErrors: PropTypes.string,
  createAccountLink: PropTypes.string.isRequired,
  forgotPasswordLink: PropTypes.string.isRequired,
};

export default SignInForm;
