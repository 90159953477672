import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import MagicText from "@shared/atoms/MagicText";
import { theme } from "@constants/theme";

const BrandButton = ({
  className,
  text,
  textType,
  target,
  href,
  onClick,
  color,
  type,
  buttonTheme,
  disabled,
  id,
  dataCy,
}) => {
  // define the button body
  // only used for type "link"
  const buttonBody = (
    <MagicText
      type={textType}
      color={
        buttonTheme === "outline" && color
          ? color.medium_dark
          : buttonTheme === "outline"
          ? theme.colors.brand.primary
          : theme.colors.grayscale.white
      }
    >
      {text}
    </MagicText>
  );

  // when href is defined and type is link, the button should return a Next <Link> element
  if (href) {
    return (
      <S.BrandButton_a
        className={className}
        color={color}
        buttontheme={buttonTheme}
        href={href}
        {...(onClick ? { onClick: onClick } : {})}
        {...(target !== "_self" ? { target: target } : {})}
        disabled={disabled}
        {...(id ? { id: id } : {})}
        data-cy={dataCy}
      >
        {buttonBody}
      </S.BrandButton_a>
    );
  }

  // if type is an input, then this button is in a form
  // and should display as a form element
  if (type === "submit") {
    return (
      <S.BrandButton_input
        className={className}
        onClick={onClick}
        color={color}
        buttontheme={buttonTheme}
        type="submit"
        value={text}
        disabled={disabled}
        {...(id ? { id: id } : {})}
        data-cy={dataCy}
      />
    );
  }

  // if type is a link, but the href is undefined then return a <button> tag
  // because this button only has an onClick
  return (
    <S.BrandButton_button
      className={className}
      onClick={onClick}
      color={color}
      buttontheme={buttonTheme}
      disabled={disabled}
      {...(id ? { id: id } : {})}
      data-cy={dataCy}
    >
      {buttonBody}
    </S.BrandButton_button>
  );
};

BrandButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["link", "submit"]),
  buttonTheme: PropTypes.oneOf(["default", "outline"]),
  target: PropTypes.oneOf(["_self", "_blank"]),
  href: PropTypes.string,
  text: PropTypes.string.isRequired,
  textType: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.oneOfType([
    PropTypes.shape({
      dark: PropTypes.string,
      medium: PropTypes.string,
      medium_dark: PropTypes.string,
      light: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  id: PropTypes.string,
  dataCy: PropTypes.string,
};

BrandButton.defaultProps = {
  textType: "buttonSmall",
  type: "link",
  buttonTheme: "default",
  target: "_self",
  dataCy: "brand-button",
  disabled: false,
};

export default BrandButton;
