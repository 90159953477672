import React from "react";
import * as S from "./styles";
import { theme } from "@constants/theme";

const Stripes = ({}) => {
  return (
    <S.Stripes_div data-cy="stripes">
      <S.Stripe_div color={theme.colors.brand.primary} />
      <S.Stripe_div color={theme.colors.brand.secondary} />
      <S.Stripe_div color={theme.colors.brand.accent_primary} />
    </S.Stripes_div>
  );
};

export default Stripes;
