import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import AuthenticationContext from "@context/AuthenticationContext";

// if you are a user, you will be redirected
// since only NON users can see this page

const UnAuthenticatedPage = ({ children, redirectTo, disableAuthRedirect }) => {
  const { user, getUser } = useContext(AuthenticationContext) || {
    user: null,
    getUser: null,
  };
  const router = useRouter();

  useEffect(() => {
    const isUserLoggedIn = async () => {
      try {
        if (!user) {
          await getUser();
        }
      } catch (e) {
        return;
      }
      router.push(redirectTo);
    };

    if (!disableAuthRedirect) {
      // if disableAuthRedirect is true, that means the user has just been logged-in
      // but we don't want to redirect them to the Auth redirect link
      // the link we instead happen through the child component
      // currently the only places this is used is on the login and create account components
      // it should only ever be used on an unauthenticted page that has specific functionality
      // that changes the user status from logged-out to logged-in
      isUserLoggedIn();
    }
  }, [user, getUser, router, redirectTo, disableAuthRedirect]);

  return <>{children}</>;
};

UnAuthenticatedPage.propTypes = {
  children: PropTypes.object.isRequired,
  redirectTo: PropTypes.string.isRequired,
  disableAuthRedirect: PropTypes.bool.isRequired,
};

export default UnAuthenticatedPage;
