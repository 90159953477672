import styled from "styled-components";

export const Stripes_div = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 8px;
  position: relative;
  z-index: 2;
`;

export const Stripe_div = styled.div`
  background-color: ${(props) => props.color};
  height: 8px;
  width: 100%;
`;
