import React from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import * as S from "./styles";
import Stripes from "@shared/atoms/Stripes";
import SVG from "@shared/atoms/SVG";
import { theme } from "@constants/theme";
import { Track } from "@util/analytics";

const NoNavLayout = ({
  children,
  meta,
  backgroundColor,
  withStripes,
  logoColor,
}) => {
  const { title } = meta;

  const trackEvent = (event) => {
    Track(event);
  };

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <S.NoNavLayout_div backgroundColor={backgroundColor}>
        {withStripes ? <Stripes /> : null}
        <S.LogoContainer_div>
          <S.LogoLink_a
            href="/"
            onClick={() => {
              trackEvent("Logo Clicked");
            }}
          >
            <SVG name="HorizontalLogo" color={logoColor} />
          </S.LogoLink_a>
        </S.LogoContainer_div>
        <S.Main_main>{children}</S.Main_main>
      </S.NoNavLayout_div>
    </>
  );
};

NoNavLayout.propTypes = {
  children: PropTypes.any.isRequired,
  meta: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  withStripes: PropTypes.bool,
  backgroundColor: PropTypes.string,
  logoColor: PropTypes.string,
};

NoNavLayout.defaultProps = {
  backgroundColor: theme.colors.grayscale.white,
  logoColor: theme.colors.brand.primary,
  withStripes: true,
};

export default NoNavLayout;
