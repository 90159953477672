import styled from "styled-components";
import Link from "next/link";

export const NoNavLayout_div = styled.div`
  height: 100%;
  overflow: auto;
  &:after {
    content: "";
    background-color: ${(props) => props.backgroundColor};
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
`;

export const Main_main = styled.main`
  z-index: 2;
  position: relative;
`;

export const LogoContainer_div = styled.div`
  margin: 34px 24px 0px 24px;
  width: 165px;
  z-index: 2;
  position: relative;
`;

export const LogoLink_a = styled(Link)`
  text-decoration: none;
`;
