import styled from "styled-components";

export const SignInContainer_div = styled.div`
  margin-top: 78px;
  margin-bottom: 216px;
  padding: 0 24px;
`;

export const FormWrapper_div = styled.div`
  margin: 0 auto;

  @media ${(props) => props.theme.device.tablet} {
    max-width: 600px;
  }
`;
