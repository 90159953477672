import styled from "styled-components";
import Link from "next/link";
import MagicText from "@shared/atoms/MagicText";
import BrandButton from "@shared/atoms/BrandButton";

export const SignInForm_div = styled.div`
  background-color: ${(props) => props.theme.colors.grayscale.white};
  border-radius: 12px;
  padding: 16px;
  text-align: center;

  @media ${(props) => props.theme.device.tablet} {
    padding: 32px;
  }
`;

export const MagicText_h1 = styled(MagicText)`
  margin-bottom: 40px;
`;

export const MagicText_p = styled(MagicText)`
  max-width: 287px;
  color: ${(props) => props.theme.colors.grayscale.steel};
  margin: 40px auto;
`;

export const CreateAccount_span = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.colors.brand.primary};
`;

export const ForgotPassword_div = styled.div`
  cursor: pointer;
  margin-bottom: 22px;
  margin-top: 24px;
  ${(props) => props.theme.typography.buttonSmall};
  color: ${(props) => props.theme.colors.brand.primary};
`;

export const Form_form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Input_input = styled.input`
  border: 1px solid ${(props) => props.theme.colors.grayscale.dust};
  border-radius: 32px;
  height: 48px;
  padding: 0 24px;
  outline: none;
  font-size: 16px;

  ::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.grayscale.smoke};
  }
`;

export const PasswordInput_input = styled.input`
  border-style: none;
  height: 48px;
  flex: 1;
  outline: none;
  font-size: 16px;

  ::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.grayscale.smoke};
  }
`;

export const PasswordFieldsContainer_div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.grayscale.dust};
  border-radius: 32px;
  padding: 0 24px;
`;

export const PasswordIcon_span = styled.span`
  float: right;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const Error_span = styled.span`
  color: ${(props) => props.theme.colors.action.strawberry};
  max-width: 350px;
  margin: 0 auto;
`;

export const BrandButton_input = styled(BrandButton)`
  height: 48px;
  margin-bottom: 8px;
`;
