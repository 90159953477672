export const processError = (errorObject) => {
  const detail = errorObject.response?.data?.detail;
  const errorData = errorObject.response?.data;

  if (detail) {
    return detail;
  } else if (errorData && typeof errorData !== "string") {
    let validationErrors = "";

    for (let error in errorData) {
      validationErrors += `${error}: ${errorData[error]
        .toString()
        .replace(/.,/g, ", ")}\n`;
    }
    return validationErrors;
  } else {
    return "oops! our bad, there seems to be an error. reach out to support or email team@realworld.co for help!";
  }
};
